table tbody tr {
  line-height: 1;
}

.card .table td,
.card .table th {
  padding-left: 0;
  padding-right: 0;
}

.table th,
.table td {
  padding: 0.5rem;
}

.card .table th:first-child {
  text-align: left !important;
  width: 200px;
  padding: 0 !important;
}
.card .table td:first-child {
  text-align: left !important;
  width: 200px;
  padding: 0 !important;
}
